/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  Upload: any;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type CancelSubscriptionInput = {
  subscription_id?: InputMaybe<Scalars['Int']>;
};

export type CancelSubscriptionResponse = {
  __typename?: 'CancelSubscriptionResponse';
  end_date?: Maybe<Scalars['String']>;
  subscription?: Maybe<Subscription>;
};

export type Card = {
  __typename?: 'Card';
  bank_code?: Maybe<Scalars['String']>;
  bank_name?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  card_number?: Maybe<Scalars['String']>;
  customer_card_id?: Maybe<Scalars['String']>;
  expiration_month?: Maybe<Scalars['String']>;
  expiration_year?: Maybe<Scalars['String']>;
  holder_name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ClaimCodeResponse = {
  __typename?: 'ClaimCodeResponse';
  convoy?: Maybe<ConvoyCode>;
};

export type Comment = {
  __typename?: 'Comment';
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['Int']>;
  replies?: Maybe<Array<Maybe<ReplyComment>>>;
  updated_at?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type CommentsResponse = {
  __typename?: 'CommentsResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Comment>>>;
};

export type CommentsWhere = {
  post_id?: InputMaybe<Scalars['Int']>;
  site?: InputMaybe<Site>;
};

export type ConnectResponse = {
  __typename?: 'ConnectResponse';
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type ConnectUserAppleInput = {
  token?: InputMaybe<Scalars['String']>;
};

export type ConnectUserFacebookInput = {
  token?: InputMaybe<Scalars['String']>;
};

export type ConnectUserGoogleInput = {
  token?: InputMaybe<Scalars['String']>;
};

export type ConvoyCode = {
  __typename?: 'ConvoyCode';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<ConvoyCodeStatuses>;
  updated_at?: Maybe<Scalars['String']>;
};

export enum ConvoyCodeStatuses {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum CouponRequestType {
  Assigned = 'ASSIGNED',
  Available = 'AVAILABLE',
  Used = 'USED',
}

export enum CouponStatuses {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum CouponTypes {
  DiscountAmount = 'DISCOUNT_AMOUNT',
  DiscountAmountAndDuration = 'DISCOUNT_AMOUNT_AND_DURATION',
  DiscountAmountAndDurationAndVolume = 'DISCOUNT_AMOUNT_AND_DURATION_AND_VOLUME',
  DiscountAmountAndDurationAndVolumeWithMonths = 'DISCOUNT_AMOUNT_AND_DURATION_AND_VOLUME_WITH_MONTHS',
  DiscountAmountAndDurationWithMonths = 'DISCOUNT_AMOUNT_AND_DURATION_WITH_MONTHS',
  DiscountAmountAndVolume = 'DISCOUNT_AMOUNT_AND_VOLUME',
  DiscountAmountAndVolumeWithMonths = 'DISCOUNT_AMOUNT_AND_VOLUME_WITH_MONTHS',
  DiscountAmountWithMonths = 'DISCOUNT_AMOUNT_WITH_MONTHS',
  DiscountPercent = 'DISCOUNT_PERCENT',
  DiscountPercentAndDuration = 'DISCOUNT_PERCENT_AND_DURATION',
  DiscountPercentAndDurationAndVolume = 'DISCOUNT_PERCENT_AND_DURATION_AND_VOLUME',
  DiscountPercentAndDurationAndVolumeWithMonths = 'DISCOUNT_PERCENT_AND_DURATION_AND_VOLUME_WITH_MONTHS',
  DiscountPercentAndDurationWithMonths = 'DISCOUNT_PERCENT_AND_DURATION_WITH_MONTHS',
  DiscountPercentAndVolume = 'DISCOUNT_PERCENT_AND_VOLUME',
  DiscountPercentAndVolumeWithMonths = 'DISCOUNT_PERCENT_AND_VOLUME_WITH_MONTHS',
  DiscountPercentWithMonths = 'DISCOUNT_PERCENT_WITH_MONTHS',
}

export type CreateCommentInput = {
  message?: InputMaybe<Scalars['String']>;
  post_id?: InputMaybe<Scalars['Int']>;
  site?: InputMaybe<Site>;
};

export type CreateCustomSubscriptionInput = {
  notes?: InputMaybe<Scalars['String']>;
  payment_method: PaymentMethod;
  period_end_date: Scalars['String'];
  period_start_date: Scalars['String'];
  plan_id: Scalars['Int'];
  transaction_id: Scalars['String'];
  user_id: Scalars['Int'];
};

export type CreateCustomSubscriptionResponse = {
  __typename?: 'CreateCustomSubscriptionResponse';
  subscription?: Maybe<Subscription>;
};

export type CreateOpenPayChargeInput = {
  billingInfo?: InputMaybe<SubscriptionBillingInput>;
  deviceDataId?: InputMaybe<Scalars['String']>;
  planId?: InputMaybe<Scalars['Int']>;
  token?: InputMaybe<Scalars['String']>;
};

export type CreateOpenPayChargeResponse = {
  __typename?: 'CreateOpenPayChargeResponse';
  redirect_url?: Maybe<Scalars['String']>;
};

export type CreateOpenPaySubscriptionInput = {
  billingInfo?: InputMaybe<SubscriptionBillingInput>;
  deviceDataId?: InputMaybe<Scalars['String']>;
  planId?: InputMaybe<Scalars['Int']>;
  subscriptionUrl?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type CreateOpenPaySubscriptionResponse = {
  __typename?: 'CreateOpenPaySubscriptionResponse';
  subscription?: Maybe<Subscription>;
};

export type CreatePaypalSubscriptionInput = {
  billingInfo?: InputMaybe<SubscriptionBillingInput>;
  coupon?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  planId?: InputMaybe<Scalars['Int']>;
  subscriptionId?: InputMaybe<Scalars['String']>;
  subscriptionUrl?: InputMaybe<Scalars['String']>;
};

export type CreatePaypalSubscriptionResponse = {
  __typename?: 'CreatePaypalSubscriptionResponse';
  subscription?: Maybe<Subscription>;
};

export type CreateReplyCommentInput = {
  comment_id?: InputMaybe<Scalars['Int']>;
  message?: InputMaybe<Scalars['String']>;
  post_id?: InputMaybe<Scalars['Int']>;
  site?: InputMaybe<Site>;
};

export type CreateSharedCouponResponse = {
  __typename?: 'CreateSharedCouponResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateSubscriptionBillingInput = {
  address?: InputMaybe<Scalars['String']>;
  address1?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phone1?: InputMaybe<Scalars['String']>;
  postal_code?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type CreateSubscriptionBillingResponse = {
  __typename?: 'CreateSubscriptionBillingResponse';
  subscriptionBilling?: Maybe<SubscriptionBilling>;
};

export type CreateUserInvitationInput = {
  email?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserRoles>;
};

export type CreateUserSavedPostInput = {
  post_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

export type CreatedAtInput = {
  gte?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<DateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DeleteUserSavedPostInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type DisableUserInput = {
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type DisconnectResponse = {
  __typename?: 'DisconnectResponse';
  message?: Maybe<Scalars['String']>;
};

export type DisconnectUserProviderInput = {
  provider?: InputMaybe<SocialProviders>;
};

export type File = {
  encoding: Scalars['String'];
  filename: Scalars['String'];
  mimetype: Scalars['String'];
};

export enum FilterSocialProviders {
  All = 'ALL',
  Apple = 'APPLE',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE',
  System = 'SYSTEM',
}

export type ForgotPasswordPayload = {
  __typename?: 'ForgotPasswordPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  NoApply = 'NO_APPLY',
}

export type IdFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<IdFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type Invoice = {
  __typename?: 'Invoice';
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  plan?: Maybe<Plan>;
  status?: Maybe<InvoiceStatuses>;
  transaction_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export enum InvoiceStatuses {
  Cancelled = 'CANCELLED',
  Paid = 'PAID',
  PaymentPending = 'PAYMENT_PENDING',
  Refunded = 'REFUNDED',
  Unpaid = 'UNPAID',
}

export type MailchimpAddSubscriptionInput = {
  audience?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type MailchimpAddSubscriptionResponse = {
  __typename?: 'MailchimpAddSubscriptionResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type MailchimpAudience = {
  __typename?: 'MailchimpAudience';
  audience?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_subscribed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type MailchimpAudienceResponse = {
  __typename?: 'MailchimpAudienceResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<MailchimpAudience>>>;
};

export type MailchimpSubscription = {
  __typename?: 'MailchimpSubscription';
  audience?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  unique_email_id?: Maybe<Scalars['String']>;
};

export type MailchimpSubscriptionResponse = {
  __typename?: 'MailchimpSubscriptionResponse';
  subscriptions?: Maybe<Array<Maybe<MailchimpSubscription>>>;
};

export type MailchimpSubscriptionWhere = {
  email?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  appleSignup?: Maybe<AuthPayload>;
  cancelSubscription?: Maybe<CancelSubscriptionResponse>;
  changePassword?: Maybe<AuthPayload>;
  claimCode?: Maybe<ClaimCodeResponse>;
  connectUserApple?: Maybe<ConnectResponse>;
  connectUserFacebook?: Maybe<ConnectResponse>;
  connectUserGoogle?: Maybe<ConnectResponse>;
  createComment?: Maybe<Comment>;
  createCustomSubscription?: Maybe<CreateCustomSubscriptionResponse>;
  createOpenPayCharge?: Maybe<CreateOpenPayChargeResponse>;
  createOpenPaySubscription?: Maybe<CreateOpenPaySubscriptionResponse>;
  createPaypalSubscription?: Maybe<CreatePaypalSubscriptionResponse>;
  createReplyComment?: Maybe<ReplyComment>;
  createSharedCoupon?: Maybe<CreateSharedCouponResponse>;
  createStudentCredential?: Maybe<StudentCredential>;
  createSubscriptionBilling?: Maybe<CreateSubscriptionBillingResponse>;
  createUserInvitation?: Maybe<UserInvitation>;
  createUserSavedPost?: Maybe<UserSavedPost>;
  deleteUserSavedPost?: Maybe<UserSavedPost>;
  disableAccount?: Maybe<DisableAccountResponse>;
  disconnectUserProvider?: Maybe<DisconnectResponse>;
  facebookSignup?: Maybe<AuthPayload>;
  forgotPassword?: Maybe<ForgotPasswordPayload>;
  googleSignup?: Maybe<AuthPayload>;
  login?: Maybe<AuthPayload>;
  mailchimpAddSubscription?: Maybe<MailchimpAddSubscriptionResponse>;
  reactivateSubscription?: Maybe<Subscription>;
  recaptcha?: Maybe<RecaptchaResponse>;
  removeUserInvitation?: Maybe<RemoveUserInvitationPayload>;
  resendUserInvitation?: Maybe<UserInvitation>;
  signup?: Maybe<AuthPayload>;
  toggleAdministratorUserRole?: Maybe<User>;
  updateCard?: Maybe<UpdateCardResponse>;
  updateSubscription?: Maybe<Subscription>;
  updateSubscriptionBilling?: Maybe<UpdateSubscriptionBillingResponse>;
  updateUser?: Maybe<User>;
  updateUserEmail?: Maybe<UpdateUserEmailResponse>;
  updateUserInvitation?: Maybe<UserInvitation>;
};

export type MutationAppleSignupArgs = {
  site: Site;
  token: Scalars['String'];
  url: Scalars['String'];
};

export type MutationCancelSubscriptionArgs = {
  data?: InputMaybe<CancelSubscriptionInput>;
};

export type MutationChangePasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationConnectUserAppleArgs = {
  data: ConnectUserAppleInput;
};

export type MutationConnectUserFacebookArgs = {
  data: ConnectUserFacebookInput;
};

export type MutationConnectUserGoogleArgs = {
  data: ConnectUserGoogleInput;
};

export type MutationCreateCommentArgs = {
  data?: InputMaybe<CreateCommentInput>;
};

export type MutationCreateCustomSubscriptionArgs = {
  data?: InputMaybe<CreateCustomSubscriptionInput>;
};

export type MutationCreateOpenPayChargeArgs = {
  data?: InputMaybe<CreateOpenPayChargeInput>;
};

export type MutationCreateOpenPaySubscriptionArgs = {
  data?: InputMaybe<CreateOpenPaySubscriptionInput>;
};

export type MutationCreatePaypalSubscriptionArgs = {
  data?: InputMaybe<CreatePaypalSubscriptionInput>;
};

export type MutationCreateReplyCommentArgs = {
  data?: InputMaybe<CreateReplyCommentInput>;
};

export type MutationCreateSharedCouponArgs = {
  coupon_id: Scalars['Int'];
  emails?: InputMaybe<Array<Scalars['String']>>;
};

export type MutationCreateStudentCredentialArgs = {
  data?: InputMaybe<StudentCredentialInput>;
};

export type MutationCreateSubscriptionBillingArgs = {
  data?: InputMaybe<CreateSubscriptionBillingInput>;
};

export type MutationCreateUserInvitationArgs = {
  data?: InputMaybe<CreateUserInvitationInput>;
};

export type MutationCreateUserSavedPostArgs = {
  data?: InputMaybe<CreateUserSavedPostInput>;
};

export type MutationDeleteUserSavedPostArgs = {
  data?: InputMaybe<DeleteUserSavedPostInput>;
};

export type MutationDisableAccountArgs = {
  data?: InputMaybe<DisableUserInput>;
};

export type MutationDisconnectUserProviderArgs = {
  data: DisconnectUserProviderInput;
};

export type MutationFacebookSignupArgs = {
  site: Site;
  token: Scalars['String'];
  url: Scalars['String'];
};

export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
  form: Scalars['String'];
  redirectUrl: Scalars['String'];
};

export type MutationGoogleSignupArgs = {
  site: Site;
  token: Scalars['String'];
  url: Scalars['String'];
};

export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationMailchimpAddSubscriptionArgs = {
  data?: InputMaybe<MailchimpAddSubscriptionInput>;
};

export type MutationReactivateSubscriptionArgs = {
  data?: InputMaybe<ReactivateSubscriptionInput>;
};

export type MutationRecaptchaArgs = {
  email: Scalars['String'];
  site?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

export type MutationRemoveUserInvitationArgs = {
  id: Scalars['String'];
};

export type MutationResendUserInvitationArgs = {
  id: Scalars['String'];
};

export type MutationSignupArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  site: Site;
  url: Scalars['String'];
};

export type MutationToggleAdministratorUserRoleArgs = {
  data?: InputMaybe<ToggleAdministratorUserRoleInput>;
};

export type MutationUpdateCardArgs = {
  data?: InputMaybe<UpdateCardInput>;
};

export type MutationUpdateSubscriptionArgs = {
  data?: InputMaybe<UpdateSubscriptionInput>;
};

export type MutationUpdateSubscriptionBillingArgs = {
  data?: InputMaybe<UpdateSubscriptionBillingInput>;
};

export type MutationUpdateUserArgs = {
  data?: InputMaybe<UpdateUserInput>;
};

export type MutationUpdateUserEmailArgs = {
  data?: InputMaybe<UpdateUserEmailInput>;
};

export type MutationUpdateUserInvitationArgs = {
  data?: InputMaybe<UpdateUserInvitationInput>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type NotificationResponse = {
  __typename?: 'NotificationResponse';
  count?: Maybe<Scalars['Int']>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
};

export type OpenPay = {
  __typename?: 'OpenPay';
  first_payment_price?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  open_pay_id?: Maybe<Scalars['String']>;
  plan_id?: Maybe<Scalars['Int']>;
  status?: Maybe<OpenPayStatuses>;
};

export enum OpenPayStatuses {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum PaymentMethod {
  Custom = 'CUSTOM',
  Openpay = 'OPENPAY',
  Paypal = 'PAYPAL',
  StripeCard = 'STRIPE_CARD',
}

export type Paypal = {
  __typename?: 'Paypal';
  id?: Maybe<Scalars['Int']>;
  paypal_id?: Maybe<Scalars['String']>;
  plan_id?: Maybe<Scalars['Int']>;
  status?: Maybe<PaypalStatuses>;
};

export enum PaypalStatuses {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type Plan = {
  __typename?: 'Plan';
  description?: Maybe<Scalars['String']>;
  frequency?: Maybe<PlanFrequency>;
  helper_text?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_premium?: Maybe<Scalars['Boolean']>;
  open_pay?: Maybe<OpenPay>;
  paypal?: Maybe<Paypal>;
  price?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<PlanStatuses>;
  stripe?: Maybe<Stripe>;
  sub_title?: Maybe<Scalars['String']>;
  subscription_billing_is_required?: Maybe<Scalars['Boolean']>;
  subscription_student_credential_is_required?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_front?: Maybe<Scalars['String']>;
};

export enum PlanFrequency {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR',
}

export type PlanResponse = {
  __typename?: 'PlanResponse';
  items?: Maybe<Array<Maybe<Plan>>>;
};

export enum PlanStatuses {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type PlanWhereInput = {
  status?: InputMaybe<PaypalStatuses>;
};

export type PlansWhereInput = {
  is_premium?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<PaypalStatuses>;
};

export type Query = {
  __typename?: 'Query';
  comments?: Maybe<CommentsResponse>;
  currentUser?: Maybe<User>;
  easyDataUsers?: Maybe<UsersPayload>;
  fetchCoupon?: Maybe<StripeCoupon>;
  mailchimpAudiences?: Maybe<MailchimpAudienceResponse>;
  mailchimpSubscriptions?: Maybe<MailchimpSubscriptionResponse>;
  notificationList?: Maybe<NotificationResponse>;
  plan?: Maybe<Plan>;
  plans?: Maybe<PlanResponse>;
  stripeCouponList?: Maybe<StripeCouponListResponse>;
  user?: Maybe<User>;
  userInvitations?: Maybe<UserInvitationsResponse>;
  userProviders?: Maybe<UserSocialProvidersResponse>;
  userSavedPosts?: Maybe<UserSavedPostsResponse>;
  users?: Maybe<UsersPayload>;
  validateCoupon?: Maybe<StripeCoupon>;
};

export type QueryCommentsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommentsWhere>;
};

export type QueryEasyDataUsersArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<UserOrderInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UsersWhere>;
};

export type QueryFetchCouponArgs = {
  couponCode: Scalars['String'];
  plan_id: Scalars['Int'];
};

export type QueryMailchimpSubscriptionsArgs = {
  where?: InputMaybe<MailchimpSubscriptionWhere>;
};

export type QueryNotificationListArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NotificationsWhere>;
};

export type QueryPlanArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryPlansArgs = {
  where?: InputMaybe<PlansWhereInput>;
};

export type QueryStripeCouponListArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StripeCouponListWhere>;
};

export type QueryUserArgs = {
  id: Scalars['Int'];
};

export type QueryUserInvitationsArgs = {
  data?: InputMaybe<UserInvitationsInput>;
};

export type QueryUserProvidersArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<UserSocialProvidersOrderInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserSocialProvidersWhereInput>;
};

export type QueryUserSavedPostsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserSavedPostsWhere>;
};

export type QueryUsersArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<UserOrderInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UsersWhere>;
};

export type QueryValidateCouponArgs = {
  couponCode: Scalars['String'];
  plan_id: Scalars['Int'];
};

export type ReactivateSubscriptionInput = {
  subscription_id?: InputMaybe<Scalars['Int']>;
};

export type RecaptchaResponse = {
  __typename?: 'RecaptchaResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type RemoveUserInvitationPayload = {
  __typename?: 'RemoveUserInvitationPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type ReplyComment = {
  __typename?: 'ReplyComment';
  comment_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type RolesOnUsers = {
  __typename?: 'RolesOnUsers';
  role?: Maybe<UserRole>;
  role_id?: Maybe<Scalars['Int']>;
};

export enum Site {
  AnimalPolitico = 'ANIMAL_POLITICO',
  CriterioHidalgo = 'CRITERIO_HIDALGO',
}

export enum SocialProviders {
  Apple = 'APPLE',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE',
  System = 'SYSTEM',
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export type SortTableInput = {
  field?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<SortOrder>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Stripe = {
  __typename?: 'Stripe';
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  plan_id?: Maybe<Scalars['Int']>;
  price_id?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type StripeCoupon = {
  __typename?: 'StripeCoupon';
  assigned_to_share?: Maybe<Scalars['Boolean']>;
  coupon?: Maybe<Scalars['String']>;
  coupon_type?: Maybe<CouponTypes>;
  created_at?: Maybe<Scalars['String']>;
  discount_amount?: Maybe<Scalars['Int']>;
  discount_percent?: Maybe<Scalars['Int']>;
  duration_in_months?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_for_sharing?: Maybe<Scalars['Boolean']>;
  is_public?: Maybe<Scalars['Boolean']>;
  max_redemptions?: Maybe<Scalars['Int']>;
  paypal_coupon_plan?: Maybe<Scalars['String']>;
  plan?: Maybe<Plan>;
  plan_names?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
  status?: Maybe<CouponStatuses>;
  stripe_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['String']>;
  used_at?: Maybe<Scalars['String']>;
};

export type StripeCouponListResponse = {
  __typename?: 'StripeCouponListResponse';
  count?: Maybe<Scalars['Int']>;
  coupons?: Maybe<Array<Maybe<StripeCoupon>>>;
};

export type StudentCredential = {
  __typename?: 'StudentCredential';
  created_at?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  school?: Maybe<Scalars['String']>;
  school_contact_number?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
  student_card?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['Int']>;
};

export type StudentCredentialDataInput = {
  expiration_date?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<Scalars['String']>;
  school_contact_number?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['String']>;
};

export type StudentCredentialInput = {
  file?: InputMaybe<Scalars['Upload']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  cancel_date?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['String']>;
  has_change_plan?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  is_cancel?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  payment_method?: Maybe<PaymentMethod>;
  period_end_date?: Maybe<Scalars['String']>;
  period_start_date?: Maybe<Scalars['String']>;
  plan?: Maybe<Plan>;
  status?: Maybe<SubscriptionStatuses>;
  transaction_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type SubscriptionBilling = {
  __typename?: 'SubscriptionBilling';
  address?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  phone1?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type SubscriptionBillingInput = {
  address?: InputMaybe<Scalars['String']>;
  address1?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phone1?: InputMaybe<Scalars['String']>;
  postal_code?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export enum SubscriptionStatuses {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Inactive = 'INACTIVE',
  Updated = 'UPDATED',
}

export type UpdateCardInput = {
  card_number?: InputMaybe<Scalars['String']>;
  cvv2?: InputMaybe<Scalars['String']>;
  device_session_id?: InputMaybe<Scalars['String']>;
  expiration_month?: InputMaybe<Scalars['String']>;
  expiration_year?: InputMaybe<Scalars['String']>;
  holder_name?: InputMaybe<Scalars['String']>;
};

export type UpdateCardResponse = {
  __typename?: 'UpdateCardResponse';
  card?: Maybe<Card>;
};

export type UpdateSubscriptionBillingInput = {
  address?: InputMaybe<Scalars['String']>;
  address1?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  phone1?: InputMaybe<Scalars['String']>;
  postal_code?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type UpdateSubscriptionBillingResponse = {
  __typename?: 'UpdateSubscriptionBillingResponse';
  subscriptionBilling?: Maybe<SubscriptionBilling>;
};

export type UpdateSubscriptionInput = {
  id?: InputMaybe<Scalars['Int']>;
  payment_method_type?: InputMaybe<PaymentMethod>;
  plan_id?: InputMaybe<Scalars['Int']>;
  subscriptionBilling?: InputMaybe<SubscriptionBillingInput>;
  subscription_id?: InputMaybe<Scalars['String']>;
};

export type UpdateUserEmailInput = {
  new_email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type UpdateUserEmailResponse = {
  __typename?: 'UpdateUserEmailResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateUserInput = {
  file?: InputMaybe<Scalars['Upload']>;
  user?: InputMaybe<UserInput>;
};

export type UpdateUserInvitationInput = {
  id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserInvitationStatuses>;
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  card?: Maybe<Card>;
  convoy_code?: Maybe<ConvoyCode>;
  created_at?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  gender?: Maybe<Gender>;
  id: Scalars['Int'];
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  name: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  post_saved?: Maybe<Array<Maybe<UserSavedPost>>>;
  roles_on_users?: Maybe<Array<Maybe<RolesOnUsers>>>;
  site?: Maybe<Site>;
  social_providers?: Maybe<Array<Maybe<UserSocialProviders>>>;
  student_credential?: Maybe<StudentCredential>;
  subscription_billing?: Maybe<SubscriptionBilling>;
  subscriptions?: Maybe<Array<Maybe<Subscription>>>;
  updated_at?: Maybe<Scalars['String']>;
  user_status?: Maybe<UserStatus>;
};

export type UserInput = {
  avatar?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  id: Scalars['Int'];
  name: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  remember_token?: InputMaybe<Scalars['String']>;
  site?: InputMaybe<Site>;
  updated_at?: InputMaybe<Scalars['String']>;
};

export type UserInvitation = {
  __typename?: 'UserInvitation';
  created_at?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoles>;
  status?: Maybe<UserInvitationStatuses>;
  updated_at?: Maybe<Scalars['String']>;
};

export enum UserInvitationStatuses {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export type UserInvitationWhereInput = {
  email?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserRoles>;
  status?: InputMaybe<UserInvitationStatuses>;
};

export type UserInvitationsResponse = {
  __typename?: 'UserInvitationsResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<UserInvitation>>>;
};

export type UserOrderInput = {
  created_at?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  user_status?: InputMaybe<SortOrder>;
};

export type UserProviderPayload = {
  __typename?: 'UserProviderPayload';
  count?: Maybe<Scalars['Int']>;
  userProviders?: Maybe<Array<Maybe<UserSocialProviders>>>;
};

export type UserProvidersWhere = {
  created_at?: InputMaybe<CreatedAtInput>;
  provider?: InputMaybe<SocialProviders>;
};

export type UserRole = {
  __typename?: 'UserRole';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<UserRoles>;
};

export enum UserRoles {
  Administrator = 'ADMINISTRATOR',
  Editor = 'EDITOR',
  Suscriptor = 'SUSCRIPTOR',
  User = 'USER',
}

export type UserSavedPost = {
  __typename?: 'UserSavedPost';
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  post_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
};

export type UserSavedPostRestApi = {
  __typename?: 'UserSavedPostRestApi';
  id?: Maybe<Scalars['Int']>;
  post_content?: Maybe<Scalars['String']>;
  post_date?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['Int']>;
  post_image?: Maybe<Scalars['String']>;
  post_name?: Maybe<Scalars['String']>;
  post_terms?: Maybe<Array<Maybe<Scalars['String']>>>;
  post_title?: Maybe<Scalars['String']>;
  post_type?: Maybe<Scalars['String']>;
  post_uri?: Maybe<Scalars['String']>;
  post_url?: Maybe<Scalars['String']>;
  sub_post_type?: Maybe<Scalars['String']>;
};

export type UserSavedPostsResponse = {
  __typename?: 'UserSavedPostsResponse';
  count?: Maybe<Scalars['Int']>;
  posts?: Maybe<Array<Maybe<UserSavedPostRestApi>>>;
};

export type UserSavedPostsWhere = {
  user_id?: InputMaybe<Scalars['Int']>;
};

export type UserSocialProviders = {
  __typename?: 'UserSocialProviders';
  created_at?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  primary?: Maybe<Scalars['Boolean']>;
  provider?: Maybe<SocialProviders>;
  provider_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  url_register?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
  users?: Maybe<User>;
};

export type UserSocialProvidersListRelationFilter = {
  every?: InputMaybe<UserSocialProvidersWhereInput>;
  none?: InputMaybe<UserSocialProvidersWhereInput>;
  some?: InputMaybe<UserSocialProvidersWhereInput>;
};

export type UserSocialProvidersOrderInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type UserSocialProvidersResponse = {
  __typename?: 'UserSocialProvidersResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<UserSocialProviders>>>;
};

export type UserSocialProvidersWhereInput = {
  created_at?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IdFilter>;
  primary?: InputMaybe<Scalars['Boolean']>;
  provider?: InputMaybe<SocialProviders>;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type UsersPayload = {
  __typename?: 'UsersPayload';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<User>>>;
};

export type UsersWhere = {
  OR?: InputMaybe<Array<InputMaybe<UsersWhere>>>;
  created_at?: InputMaybe<CreatedAtInput>;
  email?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  onlySubscribers?: InputMaybe<Scalars['Boolean']>;
  provider?: InputMaybe<FilterSocialProviders>;
  site?: InputMaybe<Site>;
};

export type DisableAccountResponse = {
  __typename?: 'disableAccountResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type NotificationsWhere = {
  user_id?: InputMaybe<Scalars['Int']>;
};

export type StripeCouponListWhere = {
  requestType?: InputMaybe<CouponRequestType>;
  user_id?: InputMaybe<Scalars['Int']>;
};

export type ToggleAdministratorUserRoleInput = {
  user_id: Scalars['Int'];
};

export type UserInvitationsInput = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortTableInput>;
  where?: InputMaybe<UserInvitationWhereInput>;
};

export type UpdateCardMutationVariables = Exact<{
  data?: InputMaybe<UpdateCardInput>;
}>;

export type UpdateCardMutation = {
  __typename?: 'Mutation';
  updateCard: {
    __typename?: 'UpdateCardResponse';
    card: {
      __typename?: 'Card';
      type: string | null;
      brand: string | null;
      card_number: string | null;
      holder_name: string | null;
      expiration_year: string | null;
      expiration_month: string | null;
      bank_name: string | null;
      bank_code: string | null;
    } | null;
  } | null;
};

export type ClaimCodeMutationVariables = Exact<{ [key: string]: never }>;

export type ClaimCodeMutation = {
  __typename?: 'Mutation';
  claimCode: {
    __typename?: 'ClaimCodeResponse';
    convoy: {
      __typename?: 'ConvoyCode';
      code: string | null;
      created_at: string | null;
      id: number | null;
      status: ConvoyCodeStatuses | null;
      updated_at: string | null;
    } | null;
  } | null;
};

export type MailchimpAddSubscriptionMutationVariables = Exact<{
  data?: InputMaybe<MailchimpAddSubscriptionInput>;
}>;

export type MailchimpAddSubscriptionMutation = {
  __typename?: 'Mutation';
  mailchimpAddSubscription: {
    __typename?: 'MailchimpAddSubscriptionResponse';
    success: boolean | null;
  } | null;
};

export type MailchimpAudiencesQueryVariables = Exact<{
  where?: InputMaybe<MailchimpSubscriptionWhere>;
}>;

export type MailchimpAudiencesQuery = {
  __typename?: 'Query';
  mailchimpAudiences: {
    __typename?: 'MailchimpAudienceResponse';
    count: number | null;
    items: Array<{
      __typename?: 'MailchimpAudience';
      audience: string | null;
      id: number | null;
      is_subscribed: boolean | null;
      name: string | null;
      description: string | null;
    } | null> | null;
  } | null;
  mailchimpSubscriptions: {
    __typename?: 'MailchimpSubscriptionResponse';
    subscriptions: Array<{
      __typename?: 'MailchimpSubscription';
      unique_email_id: string | null;
      status: string | null;
      audience: string | null;
    } | null> | null;
  } | null;
};

export type NotificationsQueryVariables = Exact<{
  where?: InputMaybe<NotificationsWhere>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;

export type NotificationsQuery = {
  __typename?: 'Query';
  notificationList: {
    __typename?: 'NotificationResponse';
    count: number | null;
    notifications: Array<{
      __typename?: 'Notification';
      id: string | null;
      message: string | null;
      created_at: string | null;
    } | null> | null;
  } | null;
};

export type CreateOpenPaySubscriptionMutationVariables = Exact<{
  data?: InputMaybe<CreateOpenPaySubscriptionInput>;
}>;

export type CreateOpenPaySubscriptionMutation = {
  __typename?: 'Mutation';
  createOpenPaySubscription: {
    __typename?: 'CreateOpenPaySubscriptionResponse';
    subscription: {
      __typename?: 'Subscription';
      id: number | null;
      status: SubscriptionStatuses | null;
      customer_id: string | null;
      period_end_date: string | null;
      period_start_date: string | null;
      created_at: string | null;
      updated_at: string | null;
    } | null;
  } | null;
};

export type CreateOpenPayChargeMutationVariables = Exact<{
  data?: InputMaybe<CreateOpenPayChargeInput>;
}>;

export type CreateOpenPayChargeMutation = {
  __typename?: 'Mutation';
  createOpenPayCharge: {
    __typename?: 'CreateOpenPayChargeResponse';
    redirect_url: string | null;
  } | null;
};

export type CreatePaypalSubscriptionMutationVariables = Exact<{
  data?: InputMaybe<CreatePaypalSubscriptionInput>;
}>;

export type CreatePaypalSubscriptionMutation = {
  __typename?: 'Mutation';
  createPaypalSubscription: {
    __typename?: 'CreatePaypalSubscriptionResponse';
    subscription: {
      __typename?: 'Subscription';
      id: number | null;
      status: SubscriptionStatuses | null;
      customer_id: string | null;
      period_end_date: string | null;
      period_start_date: string | null;
      cancel_date: string | null;
      is_cancel: boolean | null;
      payment_method: PaymentMethod | null;
      created_at: string | null;
      updated_at: string | null;
    } | null;
  } | null;
};

export type PlansQueryVariables = Exact<{
  where?: InputMaybe<PlansWhereInput>;
}>;

export type PlansQuery = {
  __typename?: 'Query';
  plans: {
    __typename?: 'PlanResponse';
    items: Array<{
      __typename?: 'Plan';
      id: number | null;
      title: string | null;
      sub_title: string | null;
      price: string | null;
      description: string | null;
      helper_text: string | null;
      frequency: PlanFrequency | null;
      subscription_billing_is_required: boolean | null;
      is_premium: boolean | null;
      status: PlanStatuses | null;
      paypal: { __typename?: 'Paypal'; paypal_id: string | null } | null;
      open_pay: {
        __typename?: 'OpenPay';
        open_pay_id: string | null;
        first_payment_price: number | null;
      } | null;
    } | null> | null;
  } | null;
};

export type PlanQueryVariables = Exact<{
  planId?: InputMaybe<Scalars['Int']>;
}>;

export type PlanQuery = {
  __typename?: 'Query';
  plan: {
    __typename?: 'Plan';
    id: number | null;
    title: string | null;
    sub_title: string | null;
    price: string | null;
    description: string | null;
    helper_text: string | null;
    status: PlanStatuses | null;
    paypal: { __typename?: 'Paypal'; paypal_id: string | null } | null;
    open_pay: { __typename?: 'OpenPay'; open_pay_id: string | null } | null;
  } | null;
};

export type CancelSubscriptionMutationVariables = Exact<{
  data?: InputMaybe<CancelSubscriptionInput>;
}>;

export type CancelSubscriptionMutation = {
  __typename?: 'Mutation';
  cancelSubscription: {
    __typename?: 'CancelSubscriptionResponse';
    subscription: {
      __typename?: 'Subscription';
      cancel_date: string | null;
      period_start_date: string | null;
      period_end_date: string | null;
      is_cancel: boolean | null;
    } | null;
  } | null;
};

export type SignupMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
  site: Site;
}>;

export type SignupMutation = {
  __typename?: 'Mutation';
  signup: {
    __typename?: 'AuthPayload';
    token: string | null;
    user: { __typename?: 'User'; id: number } | null;
  } | null;
};

export type GoogleSignupMutationVariables = Exact<{
  token: Scalars['String'];
  url: Scalars['String'];
  site: Site;
}>;

export type GoogleSignupMutation = {
  __typename?: 'Mutation';
  googleSignup: {
    __typename?: 'AuthPayload';
    token: string | null;
    user: { __typename?: 'User'; id: number } | null;
  } | null;
};

export type RecaptchaMutationVariables = Exact<{
  email: Scalars['String'];
  token: Scalars['String'];
  site?: InputMaybe<Scalars['String']>;
}>;

export type RecaptchaMutation = {
  __typename?: 'Mutation';
  recaptcha: {
    __typename?: 'RecaptchaResponse';
    success: boolean | null;
  } | null;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  __typename?: 'Query';
  currentUser: {
    __typename?: 'User';
    id: number;
    name: string;
    email: string;
    avatar: string | null;
    gender: Gender | null;
    birthday: string | null;
    site: Site | null;
    social_providers: Array<{
      __typename?: 'UserSocialProviders';
      id: number;
      provider: SocialProviders | null;
      provider_id: string | null;
      primary: boolean | null;
    } | null> | null;
    roles_on_users: Array<{
      __typename?: 'RolesOnUsers';
      role_id: number | null;
      role: {
        __typename?: 'UserRole';
        id: number | null;
        name: UserRoles | null;
      } | null;
    } | null> | null;
    subscriptions: Array<{
      __typename?: 'Subscription';
      cancel_date: string | null;
      status: SubscriptionStatuses | null;
      payment_method: PaymentMethod | null;
      period_end_date: string | null;
      period_start_date: string | null;
      is_cancel: boolean | null;
      id: number | null;
      plan: {
        __typename?: 'Plan';
        title: string | null;
        description: string | null;
        sub_title: string | null;
        price: string | null;
      } | null;
    } | null> | null;
    invoices: Array<{
      __typename?: 'Invoice';
      id: number | null;
      transaction_id: string | null;
      status: InvoiceStatuses | null;
      created_at: string | null;
      updated_at: string | null;
      plan: {
        __typename?: 'Plan';
        title: string | null;
        description: string | null;
        sub_title: string | null;
        price: string | null;
      } | null;
    } | null> | null;
    card: {
      __typename?: 'Card';
      type: string | null;
      brand: string | null;
      card_number: string | null;
      holder_name: string | null;
      expiration_year: string | null;
      expiration_month: string | null;
      bank_name: string | null;
      bank_code: string | null;
      customer_card_id: string | null;
    } | null;
    convoy_code: {
      __typename?: 'ConvoyCode';
      id: number | null;
      code: string | null;
      status: ConvoyCodeStatuses | null;
    } | null;
    subscription_billing: {
      __typename?: 'SubscriptionBilling';
      id: number | null;
      address: string | null;
      address1: string | null;
      city: string | null;
      state: string | null;
      country: string | null;
      postal_code: string | null;
      phone: string | null;
      phone1: string | null;
    } | null;
  } | null;
};

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  redirectUrl: Scalars['String'];
  form: Scalars['String'];
}>;

export type ForgotPasswordMutation = {
  __typename?: 'Mutation';
  forgotPassword: {
    __typename?: 'ForgotPasswordPayload';
    success: boolean | null;
  } | null;
};

export type ChangePasswordMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
}>;

export type ChangePasswordMutation = {
  __typename?: 'Mutation';
  changePassword: {
    __typename?: 'AuthPayload';
    token: string | null;
    user: { __typename?: 'User'; id: number } | null;
  } | null;
};

export type UpdateUserMutationVariables = Exact<{
  data?: InputMaybe<UpdateUserInput>;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    id: number;
    name: string;
    email: string;
    avatar: string | null;
    gender: Gender | null;
    birthday: string | null;
  } | null;
};

export type FacebookSignupMutationVariables = Exact<{
  token: Scalars['String'];
  url: Scalars['String'];
  site: Site;
}>;

export type FacebookSignupMutation = {
  __typename?: 'Mutation';
  facebookSignup: {
    __typename?: 'AuthPayload';
    token: string | null;
    user: { __typename?: 'User'; id: number } | null;
  } | null;
};

export type AppleSignupMutationVariables = Exact<{
  token: Scalars['String'];
  url: Scalars['String'];
  site: Site;
}>;

export type AppleSignupMutation = {
  __typename?: 'Mutation';
  appleSignup: {
    __typename?: 'AuthPayload';
    token: string | null;
    user: { __typename?: 'User'; id: number } | null;
  } | null;
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'AuthPayload';
    token: string | null;
    user: { __typename?: 'User'; id: number } | null;
  } | null;
};

export type ConnectUserGoogleMutationVariables = Exact<{
  data: ConnectUserGoogleInput;
}>;

export type ConnectUserGoogleMutation = {
  __typename?: 'Mutation';
  connectUserGoogle: {
    __typename?: 'ConnectResponse';
    token: string | null;
    user: {
      __typename?: 'User';
      id: number;
      name: string;
      email: string;
    } | null;
  } | null;
};

export type DisconnectUserProviderMutationVariables = Exact<{
  data: DisconnectUserProviderInput;
}>;

export type DisconnectUserProviderMutation = {
  __typename?: 'Mutation';
  disconnectUserProvider: {
    __typename?: 'DisconnectResponse';
    message: string | null;
  } | null;
};

export type ConnectUserFacebookMutationVariables = Exact<{
  data: ConnectUserFacebookInput;
}>;

export type ConnectUserFacebookMutation = {
  __typename?: 'Mutation';
  connectUserFacebook: {
    __typename?: 'ConnectResponse';
    token: string | null;
    user: {
      __typename?: 'User';
      id: number;
      name: string;
      email: string;
    } | null;
  } | null;
};

export type ConnectUserAppleMutationVariables = Exact<{
  data: ConnectUserAppleInput;
}>;

export type ConnectUserAppleMutation = {
  __typename?: 'Mutation';
  connectUserApple: {
    __typename?: 'ConnectResponse';
    token: string | null;
    user: {
      __typename?: 'User';
      id: number;
      name: string;
      email: string;
    } | null;
  } | null;
};

export type UserSavedPostsQueryVariables = Exact<{
  where?: InputMaybe<UserSavedPostsWhere>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;

export type UserSavedPostsQuery = {
  __typename?: 'Query';
  userSavedPosts: {
    __typename?: 'UserSavedPostsResponse';
    count: number | null;
    posts: Array<{
      __typename?: 'UserSavedPostRestApi';
      id: number | null;
      post_id: number | null;
      post_title: string | null;
      post_type: string | null;
      post_content: string | null;
      post_url: string | null;
      post_uri: string | null;
      post_date: string | null;
      post_image: string | null;
      post_name: string | null;
      post_terms: Array<string | null> | null;
      sub_post_type: string | null;
    } | null> | null;
  } | null;
};

export type CreateUserSavedPostMutationVariables = Exact<{
  data?: InputMaybe<CreateUserSavedPostInput>;
}>;

export type CreateUserSavedPostMutation = {
  __typename?: 'Mutation';
  createUserSavedPost: {
    __typename?: 'UserSavedPost';
    id: number | null;
  } | null;
};

export type DeleteUserSavedPostMutationVariables = Exact<{
  data?: InputMaybe<DeleteUserSavedPostInput>;
}>;

export type DeleteUserSavedPostMutation = {
  __typename?: 'Mutation';
  deleteUserSavedPost: {
    __typename?: 'UserSavedPost';
    id: number | null;
  } | null;
};

export type DisableAccountMutationVariables = Exact<{
  data?: InputMaybe<DisableUserInput>;
}>;

export type DisableAccountMutation = {
  __typename?: 'Mutation';
  disableAccount: {
    __typename?: 'disableAccountResponse';
    message: string | null;
    success: boolean | null;
  } | null;
};

export type UpdateUserEmailMutationVariables = Exact<{
  data?: InputMaybe<UpdateUserEmailInput>;
}>;

export type UpdateUserEmailMutation = {
  __typename?: 'Mutation';
  updateUserEmail: {
    __typename?: 'UpdateUserEmailResponse';
    message: string | null;
    success: boolean | null;
  } | null;
};

export type CreateSubscriptionBillingMutationVariables = Exact<{
  data?: InputMaybe<CreateSubscriptionBillingInput>;
}>;

export type CreateSubscriptionBillingMutation = {
  __typename?: 'Mutation';
  createSubscriptionBilling: {
    __typename?: 'CreateSubscriptionBillingResponse';
    subscriptionBilling: {
      __typename?: 'SubscriptionBilling';
      id: number | null;
    } | null;
  } | null;
};

export type UpdateSubscriptionBillingMutationVariables = Exact<{
  data?: InputMaybe<UpdateSubscriptionBillingInput>;
}>;

export type UpdateSubscriptionBillingMutation = {
  __typename?: 'Mutation';
  updateSubscriptionBilling: {
    __typename?: 'UpdateSubscriptionBillingResponse';
    subscriptionBilling: {
      __typename?: 'SubscriptionBilling';
      id: number | null;
    } | null;
  } | null;
};

export const UpdateCardDocument = gql`
  mutation UpdateCard($data: UpdateCardInput) {
    updateCard(data: $data) {
      card {
        type
        brand
        card_number
        holder_name
        expiration_year
        expiration_month
        bank_name
        bank_code
      }
    }
  }
`;
export type UpdateCardMutationFn = Apollo.MutationFunction<
  UpdateCardMutation,
  UpdateCardMutationVariables
>;

/**
 * __useUpdateCardMutation__
 *
 * To run a mutation, you first call `useUpdateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCardMutation, { data, loading, error }] = useUpdateCardMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCardMutation,
    UpdateCardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCardMutation, UpdateCardMutationVariables>(
    UpdateCardDocument,
    options,
  );
}
export type UpdateCardMutationHookResult = ReturnType<
  typeof useUpdateCardMutation
>;
export type UpdateCardMutationResult =
  Apollo.MutationResult<UpdateCardMutation>;
export type UpdateCardMutationOptions = Apollo.BaseMutationOptions<
  UpdateCardMutation,
  UpdateCardMutationVariables
>;
export const ClaimCodeDocument = gql`
  mutation ClaimCode {
    claimCode {
      convoy {
        code
        created_at
        id
        status
        updated_at
      }
    }
  }
`;
export type ClaimCodeMutationFn = Apollo.MutationFunction<
  ClaimCodeMutation,
  ClaimCodeMutationVariables
>;

/**
 * __useClaimCodeMutation__
 *
 * To run a mutation, you first call `useClaimCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimCodeMutation, { data, loading, error }] = useClaimCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useClaimCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClaimCodeMutation,
    ClaimCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ClaimCodeMutation, ClaimCodeMutationVariables>(
    ClaimCodeDocument,
    options,
  );
}
export type ClaimCodeMutationHookResult = ReturnType<
  typeof useClaimCodeMutation
>;
export type ClaimCodeMutationResult = Apollo.MutationResult<ClaimCodeMutation>;
export type ClaimCodeMutationOptions = Apollo.BaseMutationOptions<
  ClaimCodeMutation,
  ClaimCodeMutationVariables
>;
export const MailchimpAddSubscriptionDocument = gql`
  mutation MailchimpAddSubscription($data: MailchimpAddSubscriptionInput) {
    mailchimpAddSubscription(data: $data) {
      success
    }
  }
`;
export type MailchimpAddSubscriptionMutationFn = Apollo.MutationFunction<
  MailchimpAddSubscriptionMutation,
  MailchimpAddSubscriptionMutationVariables
>;

/**
 * __useMailchimpAddSubscriptionMutation__
 *
 * To run a mutation, you first call `useMailchimpAddSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMailchimpAddSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mailchimpAddSubscriptionMutation, { data, loading, error }] = useMailchimpAddSubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMailchimpAddSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MailchimpAddSubscriptionMutation,
    MailchimpAddSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MailchimpAddSubscriptionMutation,
    MailchimpAddSubscriptionMutationVariables
  >(MailchimpAddSubscriptionDocument, options);
}
export type MailchimpAddSubscriptionMutationHookResult = ReturnType<
  typeof useMailchimpAddSubscriptionMutation
>;
export type MailchimpAddSubscriptionMutationResult =
  Apollo.MutationResult<MailchimpAddSubscriptionMutation>;
export type MailchimpAddSubscriptionMutationOptions =
  Apollo.BaseMutationOptions<
    MailchimpAddSubscriptionMutation,
    MailchimpAddSubscriptionMutationVariables
  >;
export const MailchimpAudiencesDocument = gql`
  query MailchimpAudiences($where: MailchimpSubscriptionWhere) {
    mailchimpAudiences {
      count
      items {
        audience
        id
        is_subscribed
        name
        description
      }
    }
    mailchimpSubscriptions(where: $where) {
      subscriptions {
        unique_email_id
        status
        audience
      }
    }
  }
`;

/**
 * __useMailchimpAudiencesQuery__
 *
 * To run a query within a React component, call `useMailchimpAudiencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMailchimpAudiencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMailchimpAudiencesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMailchimpAudiencesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MailchimpAudiencesQuery,
    MailchimpAudiencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MailchimpAudiencesQuery,
    MailchimpAudiencesQueryVariables
  >(MailchimpAudiencesDocument, options);
}
export function useMailchimpAudiencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MailchimpAudiencesQuery,
    MailchimpAudiencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MailchimpAudiencesQuery,
    MailchimpAudiencesQueryVariables
  >(MailchimpAudiencesDocument, options);
}
export type MailchimpAudiencesQueryHookResult = ReturnType<
  typeof useMailchimpAudiencesQuery
>;
export type MailchimpAudiencesLazyQueryHookResult = ReturnType<
  typeof useMailchimpAudiencesLazyQuery
>;
export type MailchimpAudiencesQueryResult = Apollo.QueryResult<
  MailchimpAudiencesQuery,
  MailchimpAudiencesQueryVariables
>;
export const NotificationsDocument = gql`
  query Notifications($where: notificationsWhere, $page: Int, $pageSize: Int) {
    notificationList(where: $where, page: $page, pageSize: $pageSize) {
      notifications {
        id
        message
        created_at
      }
      count
    }
  }
`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options,
  );
}
export function useNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options,
  );
}
export type NotificationsQueryHookResult = ReturnType<
  typeof useNotificationsQuery
>;
export type NotificationsLazyQueryHookResult = ReturnType<
  typeof useNotificationsLazyQuery
>;
export type NotificationsQueryResult = Apollo.QueryResult<
  NotificationsQuery,
  NotificationsQueryVariables
>;
export const CreateOpenPaySubscriptionDocument = gql`
  mutation CreateOpenPaySubscription($data: CreateOpenPaySubscriptionInput) {
    createOpenPaySubscription(data: $data) {
      subscription {
        id
        status
        customer_id
        period_end_date
        period_start_date
        created_at
        updated_at
      }
    }
  }
`;
export type CreateOpenPaySubscriptionMutationFn = Apollo.MutationFunction<
  CreateOpenPaySubscriptionMutation,
  CreateOpenPaySubscriptionMutationVariables
>;

/**
 * __useCreateOpenPaySubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateOpenPaySubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOpenPaySubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOpenPaySubscriptionMutation, { data, loading, error }] = useCreateOpenPaySubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOpenPaySubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOpenPaySubscriptionMutation,
    CreateOpenPaySubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOpenPaySubscriptionMutation,
    CreateOpenPaySubscriptionMutationVariables
  >(CreateOpenPaySubscriptionDocument, options);
}
export type CreateOpenPaySubscriptionMutationHookResult = ReturnType<
  typeof useCreateOpenPaySubscriptionMutation
>;
export type CreateOpenPaySubscriptionMutationResult =
  Apollo.MutationResult<CreateOpenPaySubscriptionMutation>;
export type CreateOpenPaySubscriptionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateOpenPaySubscriptionMutation,
    CreateOpenPaySubscriptionMutationVariables
  >;
export const CreateOpenPayChargeDocument = gql`
  mutation CreateOpenPayCharge($data: CreateOpenPayChargeInput) {
    createOpenPayCharge(data: $data) {
      redirect_url
    }
  }
`;
export type CreateOpenPayChargeMutationFn = Apollo.MutationFunction<
  CreateOpenPayChargeMutation,
  CreateOpenPayChargeMutationVariables
>;

/**
 * __useCreateOpenPayChargeMutation__
 *
 * To run a mutation, you first call `useCreateOpenPayChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOpenPayChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOpenPayChargeMutation, { data, loading, error }] = useCreateOpenPayChargeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOpenPayChargeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOpenPayChargeMutation,
    CreateOpenPayChargeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOpenPayChargeMutation,
    CreateOpenPayChargeMutationVariables
  >(CreateOpenPayChargeDocument, options);
}
export type CreateOpenPayChargeMutationHookResult = ReturnType<
  typeof useCreateOpenPayChargeMutation
>;
export type CreateOpenPayChargeMutationResult =
  Apollo.MutationResult<CreateOpenPayChargeMutation>;
export type CreateOpenPayChargeMutationOptions = Apollo.BaseMutationOptions<
  CreateOpenPayChargeMutation,
  CreateOpenPayChargeMutationVariables
>;
export const CreatePaypalSubscriptionDocument = gql`
  mutation CreatePaypalSubscription($data: CreatePaypalSubscriptionInput) {
    createPaypalSubscription(data: $data) {
      subscription {
        id
        status
        customer_id
        period_end_date
        period_start_date
        cancel_date
        is_cancel
        payment_method
        created_at
        updated_at
      }
    }
  }
`;
export type CreatePaypalSubscriptionMutationFn = Apollo.MutationFunction<
  CreatePaypalSubscriptionMutation,
  CreatePaypalSubscriptionMutationVariables
>;

/**
 * __useCreatePaypalSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreatePaypalSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaypalSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaypalSubscriptionMutation, { data, loading, error }] = useCreatePaypalSubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePaypalSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePaypalSubscriptionMutation,
    CreatePaypalSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePaypalSubscriptionMutation,
    CreatePaypalSubscriptionMutationVariables
  >(CreatePaypalSubscriptionDocument, options);
}
export type CreatePaypalSubscriptionMutationHookResult = ReturnType<
  typeof useCreatePaypalSubscriptionMutation
>;
export type CreatePaypalSubscriptionMutationResult =
  Apollo.MutationResult<CreatePaypalSubscriptionMutation>;
export type CreatePaypalSubscriptionMutationOptions =
  Apollo.BaseMutationOptions<
    CreatePaypalSubscriptionMutation,
    CreatePaypalSubscriptionMutationVariables
  >;
export const PlansDocument = gql`
  query Plans($where: PlansWhereInput) {
    plans(where: $where) {
      items {
        id
        title
        sub_title
        price
        description
        helper_text
        frequency
        subscription_billing_is_required
        is_premium
        paypal {
          paypal_id
        }
        status
        open_pay {
          open_pay_id
          first_payment_price
        }
      }
    }
  }
`;

/**
 * __usePlansQuery__
 *
 * To run a query within a React component, call `usePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePlansQuery(
  baseOptions?: Apollo.QueryHookOptions<PlansQuery, PlansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlansQuery, PlansQueryVariables>(
    PlansDocument,
    options,
  );
}
export function usePlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PlansQuery, PlansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlansQuery, PlansQueryVariables>(
    PlansDocument,
    options,
  );
}
export type PlansQueryHookResult = ReturnType<typeof usePlansQuery>;
export type PlansLazyQueryHookResult = ReturnType<typeof usePlansLazyQuery>;
export type PlansQueryResult = Apollo.QueryResult<
  PlansQuery,
  PlansQueryVariables
>;
export const PlanDocument = gql`
  query Plan($planId: Int) {
    plan(id: $planId) {
      id
      title
      sub_title
      price
      description
      helper_text
      paypal {
        paypal_id
      }
      status
      open_pay {
        open_pay_id
      }
    }
  }
`;

/**
 * __usePlanQuery__
 *
 * To run a query within a React component, call `usePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function usePlanQuery(
  baseOptions?: Apollo.QueryHookOptions<PlanQuery, PlanQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlanQuery, PlanQueryVariables>(PlanDocument, options);
}
export function usePlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PlanQuery, PlanQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlanQuery, PlanQueryVariables>(
    PlanDocument,
    options,
  );
}
export type PlanQueryHookResult = ReturnType<typeof usePlanQuery>;
export type PlanLazyQueryHookResult = ReturnType<typeof usePlanLazyQuery>;
export type PlanQueryResult = Apollo.QueryResult<PlanQuery, PlanQueryVariables>;
export const CancelSubscriptionDocument = gql`
  mutation CancelSubscription($data: CancelSubscriptionInput) {
    cancelSubscription(data: $data) {
      subscription {
        cancel_date
        period_start_date
        period_end_date
        is_cancel
      }
    }
  }
`;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >(CancelSubscriptionDocument, options);
}
export type CancelSubscriptionMutationHookResult = ReturnType<
  typeof useCancelSubscriptionMutation
>;
export type CancelSubscriptionMutationResult =
  Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>;
export const SignupDocument = gql`
  mutation Signup(
    $email: String!
    $password: String!
    $name: String!
    $url: String!
    $site: Site!
  ) {
    signup(
      email: $email
      password: $password
      name: $name
      url: $url
      site: $site
    ) {
      user {
        id
      }
      token
    }
  }
`;
export type SignupMutationFn = Apollo.MutationFunction<
  SignupMutation,
  SignupMutationVariables
>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      name: // value for 'name'
 *      url: // value for 'url'
 *      site: // value for 'site'
 *   },
 * });
 */
export function useSignupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignupMutation,
    SignupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignupMutation, SignupMutationVariables>(
    SignupDocument,
    options,
  );
}
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<
  SignupMutation,
  SignupMutationVariables
>;
export const GoogleSignupDocument = gql`
  mutation googleSignup($token: String!, $url: String!, $site: Site!) {
    googleSignup(token: $token, url: $url, site: $site) {
      user {
        id
      }
      token
    }
  }
`;
export type GoogleSignupMutationFn = Apollo.MutationFunction<
  GoogleSignupMutation,
  GoogleSignupMutationVariables
>;

/**
 * __useGoogleSignupMutation__
 *
 * To run a mutation, you first call `useGoogleSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleSignupMutation, { data, loading, error }] = useGoogleSignupMutation({
 *   variables: {
 *      token: // value for 'token'
 *      url: // value for 'url'
 *      site: // value for 'site'
 *   },
 * });
 */
export function useGoogleSignupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GoogleSignupMutation,
    GoogleSignupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GoogleSignupMutation,
    GoogleSignupMutationVariables
  >(GoogleSignupDocument, options);
}
export type GoogleSignupMutationHookResult = ReturnType<
  typeof useGoogleSignupMutation
>;
export type GoogleSignupMutationResult =
  Apollo.MutationResult<GoogleSignupMutation>;
export type GoogleSignupMutationOptions = Apollo.BaseMutationOptions<
  GoogleSignupMutation,
  GoogleSignupMutationVariables
>;
export const RecaptchaDocument = gql`
  mutation Recaptcha($email: String!, $token: String!, $site: String) {
    recaptcha(email: $email, token: $token, site: $site) {
      success
    }
  }
`;
export type RecaptchaMutationFn = Apollo.MutationFunction<
  RecaptchaMutation,
  RecaptchaMutationVariables
>;

/**
 * __useRecaptchaMutation__
 *
 * To run a mutation, you first call `useRecaptchaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecaptchaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recaptchaMutation, { data, loading, error }] = useRecaptchaMutation({
 *   variables: {
 *      email: // value for 'email'
 *      token: // value for 'token'
 *      site: // value for 'site'
 *   },
 * });
 */
export function useRecaptchaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RecaptchaMutation,
    RecaptchaMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RecaptchaMutation, RecaptchaMutationVariables>(
    RecaptchaDocument,
    options,
  );
}
export type RecaptchaMutationHookResult = ReturnType<
  typeof useRecaptchaMutation
>;
export type RecaptchaMutationResult = Apollo.MutationResult<RecaptchaMutation>;
export type RecaptchaMutationOptions = Apollo.BaseMutationOptions<
  RecaptchaMutation,
  RecaptchaMutationVariables
>;
export const CurrentUserDocument = gql`
  query CurrentUser {
    currentUser {
      id
      name
      email
      avatar
      gender
      birthday
      site
      social_providers {
        id
        provider
        provider_id
        primary
      }
      roles_on_users {
        role_id
        role {
          id
          name
        }
      }
      subscriptions {
        plan {
          title
          description
          sub_title
          price
        }
        cancel_date
        status
        payment_method
        period_end_date
        period_start_date
        is_cancel
        id
      }
      invoices {
        id
        transaction_id
        status
        created_at
        updated_at
        plan {
          title
          description
          sub_title
          price
        }
      }
      card {
        type
        brand
        card_number
        holder_name
        expiration_year
        expiration_month
        bank_name
        bank_code
        customer_card_id
      }
      convoy_code {
        id
        code
        status
      }
      subscription_billing {
        id
        address
        address1
        city
        state
        country
        postal_code
        phone
        phone1
      }
    }
  }
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options,
  );
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options,
  );
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<
  typeof useCurrentUserLazyQuery
>;
export type CurrentUserQueryResult = Apollo.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>;
export const ForgotPasswordDocument = gql`
  mutation ForgotPassword(
    $email: String!
    $redirectUrl: String!
    $form: String!
  ) {
    forgotPassword(email: $email, redirectUrl: $redirectUrl, form: $form) {
      success
    }
  }
`;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      redirectUrl: // value for 'redirectUrl'
 *      form: // value for 'form'
 *   },
 * });
 */
export function useForgotPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >(ForgotPasswordDocument, options);
}
export type ForgotPasswordMutationHookResult = ReturnType<
  typeof useForgotPasswordMutation
>;
export type ForgotPasswordMutationResult =
  Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;
export const ChangePasswordDocument = gql`
  mutation ChangePassword(
    $email: String!
    $password: String!
    $token: String!
  ) {
    changePassword(email: $email, password: $password, token: $token) {
      user {
        id
      }
      token
    }
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<
  typeof useChangePasswordMutation
>;
export type ChangePasswordMutationResult =
  Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($data: UpdateUserInput) {
    updateUser(data: $data) {
      id
      name
      email
      avatar
      gender
      birthday
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const FacebookSignupDocument = gql`
  mutation FacebookSignup($token: String!, $url: String!, $site: Site!) {
    facebookSignup(token: $token, url: $url, site: $site) {
      user {
        id
      }
      token
    }
  }
`;
export type FacebookSignupMutationFn = Apollo.MutationFunction<
  FacebookSignupMutation,
  FacebookSignupMutationVariables
>;

/**
 * __useFacebookSignupMutation__
 *
 * To run a mutation, you first call `useFacebookSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFacebookSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [facebookSignupMutation, { data, loading, error }] = useFacebookSignupMutation({
 *   variables: {
 *      token: // value for 'token'
 *      url: // value for 'url'
 *      site: // value for 'site'
 *   },
 * });
 */
export function useFacebookSignupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FacebookSignupMutation,
    FacebookSignupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FacebookSignupMutation,
    FacebookSignupMutationVariables
  >(FacebookSignupDocument, options);
}
export type FacebookSignupMutationHookResult = ReturnType<
  typeof useFacebookSignupMutation
>;
export type FacebookSignupMutationResult =
  Apollo.MutationResult<FacebookSignupMutation>;
export type FacebookSignupMutationOptions = Apollo.BaseMutationOptions<
  FacebookSignupMutation,
  FacebookSignupMutationVariables
>;
export const AppleSignupDocument = gql`
  mutation AppleSignup($token: String!, $url: String!, $site: Site!) {
    appleSignup(token: $token, url: $url, site: $site) {
      user {
        id
      }
      token
    }
  }
`;
export type AppleSignupMutationFn = Apollo.MutationFunction<
  AppleSignupMutation,
  AppleSignupMutationVariables
>;

/**
 * __useAppleSignupMutation__
 *
 * To run a mutation, you first call `useAppleSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppleSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appleSignupMutation, { data, loading, error }] = useAppleSignupMutation({
 *   variables: {
 *      token: // value for 'token'
 *      url: // value for 'url'
 *      site: // value for 'site'
 *   },
 * });
 */
export function useAppleSignupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AppleSignupMutation,
    AppleSignupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AppleSignupMutation, AppleSignupMutationVariables>(
    AppleSignupDocument,
    options,
  );
}
export type AppleSignupMutationHookResult = ReturnType<
  typeof useAppleSignupMutation
>;
export type AppleSignupMutationResult =
  Apollo.MutationResult<AppleSignupMutation>;
export type AppleSignupMutationOptions = Apollo.BaseMutationOptions<
  AppleSignupMutation,
  AppleSignupMutationVariables
>;
export const LoginDocument = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      user {
        id
      }
      token
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const ConnectUserGoogleDocument = gql`
  mutation ConnectUserGoogle($data: ConnectUserGoogleInput!) {
    connectUserGoogle(data: $data) {
      token
      user {
        id
        name
        email
      }
    }
  }
`;
export type ConnectUserGoogleMutationFn = Apollo.MutationFunction<
  ConnectUserGoogleMutation,
  ConnectUserGoogleMutationVariables
>;

/**
 * __useConnectUserGoogleMutation__
 *
 * To run a mutation, you first call `useConnectUserGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectUserGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectUserGoogleMutation, { data, loading, error }] = useConnectUserGoogleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useConnectUserGoogleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectUserGoogleMutation,
    ConnectUserGoogleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConnectUserGoogleMutation,
    ConnectUserGoogleMutationVariables
  >(ConnectUserGoogleDocument, options);
}
export type ConnectUserGoogleMutationHookResult = ReturnType<
  typeof useConnectUserGoogleMutation
>;
export type ConnectUserGoogleMutationResult =
  Apollo.MutationResult<ConnectUserGoogleMutation>;
export type ConnectUserGoogleMutationOptions = Apollo.BaseMutationOptions<
  ConnectUserGoogleMutation,
  ConnectUserGoogleMutationVariables
>;
export const DisconnectUserProviderDocument = gql`
  mutation DisconnectUserProvider($data: DisconnectUserProviderInput!) {
    disconnectUserProvider(data: $data) {
      message
    }
  }
`;
export type DisconnectUserProviderMutationFn = Apollo.MutationFunction<
  DisconnectUserProviderMutation,
  DisconnectUserProviderMutationVariables
>;

/**
 * __useDisconnectUserProviderMutation__
 *
 * To run a mutation, you first call `useDisconnectUserProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectUserProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectUserProviderMutation, { data, loading, error }] = useDisconnectUserProviderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDisconnectUserProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisconnectUserProviderMutation,
    DisconnectUserProviderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisconnectUserProviderMutation,
    DisconnectUserProviderMutationVariables
  >(DisconnectUserProviderDocument, options);
}
export type DisconnectUserProviderMutationHookResult = ReturnType<
  typeof useDisconnectUserProviderMutation
>;
export type DisconnectUserProviderMutationResult =
  Apollo.MutationResult<DisconnectUserProviderMutation>;
export type DisconnectUserProviderMutationOptions = Apollo.BaseMutationOptions<
  DisconnectUserProviderMutation,
  DisconnectUserProviderMutationVariables
>;
export const ConnectUserFacebookDocument = gql`
  mutation ConnectUserFacebook($data: ConnectUserFacebookInput!) {
    connectUserFacebook(data: $data) {
      token
      user {
        id
        name
        email
      }
    }
  }
`;
export type ConnectUserFacebookMutationFn = Apollo.MutationFunction<
  ConnectUserFacebookMutation,
  ConnectUserFacebookMutationVariables
>;

/**
 * __useConnectUserFacebookMutation__
 *
 * To run a mutation, you first call `useConnectUserFacebookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectUserFacebookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectUserFacebookMutation, { data, loading, error }] = useConnectUserFacebookMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useConnectUserFacebookMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectUserFacebookMutation,
    ConnectUserFacebookMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConnectUserFacebookMutation,
    ConnectUserFacebookMutationVariables
  >(ConnectUserFacebookDocument, options);
}
export type ConnectUserFacebookMutationHookResult = ReturnType<
  typeof useConnectUserFacebookMutation
>;
export type ConnectUserFacebookMutationResult =
  Apollo.MutationResult<ConnectUserFacebookMutation>;
export type ConnectUserFacebookMutationOptions = Apollo.BaseMutationOptions<
  ConnectUserFacebookMutation,
  ConnectUserFacebookMutationVariables
>;
export const ConnectUserAppleDocument = gql`
  mutation ConnectUserApple($data: ConnectUserAppleInput!) {
    connectUserApple(data: $data) {
      token
      user {
        id
        name
        email
      }
    }
  }
`;
export type ConnectUserAppleMutationFn = Apollo.MutationFunction<
  ConnectUserAppleMutation,
  ConnectUserAppleMutationVariables
>;

/**
 * __useConnectUserAppleMutation__
 *
 * To run a mutation, you first call `useConnectUserAppleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectUserAppleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectUserAppleMutation, { data, loading, error }] = useConnectUserAppleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useConnectUserAppleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectUserAppleMutation,
    ConnectUserAppleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConnectUserAppleMutation,
    ConnectUserAppleMutationVariables
  >(ConnectUserAppleDocument, options);
}
export type ConnectUserAppleMutationHookResult = ReturnType<
  typeof useConnectUserAppleMutation
>;
export type ConnectUserAppleMutationResult =
  Apollo.MutationResult<ConnectUserAppleMutation>;
export type ConnectUserAppleMutationOptions = Apollo.BaseMutationOptions<
  ConnectUserAppleMutation,
  ConnectUserAppleMutationVariables
>;
export const UserSavedPostsDocument = gql`
  query UserSavedPosts(
    $where: UserSavedPostsWhere
    $page: Int
    $pageSize: Int
  ) {
    userSavedPosts(where: $where, page: $page, pageSize: $pageSize) {
      posts {
        id
        post_id
        post_title
        post_type
        post_content
        post_url
        post_uri
        post_date
        post_image
        post_name
        post_terms
        sub_post_type
      }
      count
    }
  }
`;

/**
 * __useUserSavedPostsQuery__
 *
 * To run a query within a React component, call `useUserSavedPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSavedPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSavedPostsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useUserSavedPostsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserSavedPostsQuery,
    UserSavedPostsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserSavedPostsQuery, UserSavedPostsQueryVariables>(
    UserSavedPostsDocument,
    options,
  );
}
export function useUserSavedPostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSavedPostsQuery,
    UserSavedPostsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserSavedPostsQuery, UserSavedPostsQueryVariables>(
    UserSavedPostsDocument,
    options,
  );
}
export type UserSavedPostsQueryHookResult = ReturnType<
  typeof useUserSavedPostsQuery
>;
export type UserSavedPostsLazyQueryHookResult = ReturnType<
  typeof useUserSavedPostsLazyQuery
>;
export type UserSavedPostsQueryResult = Apollo.QueryResult<
  UserSavedPostsQuery,
  UserSavedPostsQueryVariables
>;
export const CreateUserSavedPostDocument = gql`
  mutation CreateUserSavedPost($data: CreateUserSavedPostInput) {
    createUserSavedPost(data: $data) {
      id
    }
  }
`;
export type CreateUserSavedPostMutationFn = Apollo.MutationFunction<
  CreateUserSavedPostMutation,
  CreateUserSavedPostMutationVariables
>;

/**
 * __useCreateUserSavedPostMutation__
 *
 * To run a mutation, you first call `useCreateUserSavedPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserSavedPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserSavedPostMutation, { data, loading, error }] = useCreateUserSavedPostMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserSavedPostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserSavedPostMutation,
    CreateUserSavedPostMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserSavedPostMutation,
    CreateUserSavedPostMutationVariables
  >(CreateUserSavedPostDocument, options);
}
export type CreateUserSavedPostMutationHookResult = ReturnType<
  typeof useCreateUserSavedPostMutation
>;
export type CreateUserSavedPostMutationResult =
  Apollo.MutationResult<CreateUserSavedPostMutation>;
export type CreateUserSavedPostMutationOptions = Apollo.BaseMutationOptions<
  CreateUserSavedPostMutation,
  CreateUserSavedPostMutationVariables
>;
export const DeleteUserSavedPostDocument = gql`
  mutation DeleteUserSavedPost($data: DeleteUserSavedPostInput) {
    deleteUserSavedPost(data: $data) {
      id
    }
  }
`;
export type DeleteUserSavedPostMutationFn = Apollo.MutationFunction<
  DeleteUserSavedPostMutation,
  DeleteUserSavedPostMutationVariables
>;

/**
 * __useDeleteUserSavedPostMutation__
 *
 * To run a mutation, you first call `useDeleteUserSavedPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserSavedPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserSavedPostMutation, { data, loading, error }] = useDeleteUserSavedPostMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteUserSavedPostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserSavedPostMutation,
    DeleteUserSavedPostMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserSavedPostMutation,
    DeleteUserSavedPostMutationVariables
  >(DeleteUserSavedPostDocument, options);
}
export type DeleteUserSavedPostMutationHookResult = ReturnType<
  typeof useDeleteUserSavedPostMutation
>;
export type DeleteUserSavedPostMutationResult =
  Apollo.MutationResult<DeleteUserSavedPostMutation>;
export type DeleteUserSavedPostMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserSavedPostMutation,
  DeleteUserSavedPostMutationVariables
>;
export const DisableAccountDocument = gql`
  mutation DisableAccount($data: DisableUserInput) {
    disableAccount(data: $data) {
      message
      success
    }
  }
`;
export type DisableAccountMutationFn = Apollo.MutationFunction<
  DisableAccountMutation,
  DisableAccountMutationVariables
>;

/**
 * __useDisableAccountMutation__
 *
 * To run a mutation, you first call `useDisableAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableAccountMutation, { data, loading, error }] = useDisableAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDisableAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisableAccountMutation,
    DisableAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisableAccountMutation,
    DisableAccountMutationVariables
  >(DisableAccountDocument, options);
}
export type DisableAccountMutationHookResult = ReturnType<
  typeof useDisableAccountMutation
>;
export type DisableAccountMutationResult =
  Apollo.MutationResult<DisableAccountMutation>;
export type DisableAccountMutationOptions = Apollo.BaseMutationOptions<
  DisableAccountMutation,
  DisableAccountMutationVariables
>;
export const UpdateUserEmailDocument = gql`
  mutation UpdateUserEmail($data: UpdateUserEmailInput) {
    updateUserEmail(data: $data) {
      message
      success
    }
  }
`;
export type UpdateUserEmailMutationFn = Apollo.MutationFunction<
  UpdateUserEmailMutation,
  UpdateUserEmailMutationVariables
>;

/**
 * __useUpdateUserEmailMutation__
 *
 * To run a mutation, you first call `useUpdateUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserEmailMutation, { data, loading, error }] = useUpdateUserEmailMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserEmailMutation,
    UpdateUserEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserEmailMutation,
    UpdateUserEmailMutationVariables
  >(UpdateUserEmailDocument, options);
}
export type UpdateUserEmailMutationHookResult = ReturnType<
  typeof useUpdateUserEmailMutation
>;
export type UpdateUserEmailMutationResult =
  Apollo.MutationResult<UpdateUserEmailMutation>;
export type UpdateUserEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserEmailMutation,
  UpdateUserEmailMutationVariables
>;
export const CreateSubscriptionBillingDocument = gql`
  mutation CreateSubscriptionBilling($data: CreateSubscriptionBillingInput) {
    createSubscriptionBilling(data: $data) {
      subscriptionBilling {
        id
      }
    }
  }
`;
export type CreateSubscriptionBillingMutationFn = Apollo.MutationFunction<
  CreateSubscriptionBillingMutation,
  CreateSubscriptionBillingMutationVariables
>;

/**
 * __useCreateSubscriptionBillingMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionBillingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionBillingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionBillingMutation, { data, loading, error }] = useCreateSubscriptionBillingMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSubscriptionBillingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSubscriptionBillingMutation,
    CreateSubscriptionBillingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSubscriptionBillingMutation,
    CreateSubscriptionBillingMutationVariables
  >(CreateSubscriptionBillingDocument, options);
}
export type CreateSubscriptionBillingMutationHookResult = ReturnType<
  typeof useCreateSubscriptionBillingMutation
>;
export type CreateSubscriptionBillingMutationResult =
  Apollo.MutationResult<CreateSubscriptionBillingMutation>;
export type CreateSubscriptionBillingMutationOptions =
  Apollo.BaseMutationOptions<
    CreateSubscriptionBillingMutation,
    CreateSubscriptionBillingMutationVariables
  >;
export const UpdateSubscriptionBillingDocument = gql`
  mutation UpdateSubscriptionBilling($data: UpdateSubscriptionBillingInput) {
    updateSubscriptionBilling(data: $data) {
      subscriptionBilling {
        id
      }
    }
  }
`;
export type UpdateSubscriptionBillingMutationFn = Apollo.MutationFunction<
  UpdateSubscriptionBillingMutation,
  UpdateSubscriptionBillingMutationVariables
>;

/**
 * __useUpdateSubscriptionBillingMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionBillingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionBillingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionBillingMutation, { data, loading, error }] = useUpdateSubscriptionBillingMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSubscriptionBillingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSubscriptionBillingMutation,
    UpdateSubscriptionBillingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSubscriptionBillingMutation,
    UpdateSubscriptionBillingMutationVariables
  >(UpdateSubscriptionBillingDocument, options);
}
export type UpdateSubscriptionBillingMutationHookResult = ReturnType<
  typeof useUpdateSubscriptionBillingMutation
>;
export type UpdateSubscriptionBillingMutationResult =
  Apollo.MutationResult<UpdateSubscriptionBillingMutation>;
export type UpdateSubscriptionBillingMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateSubscriptionBillingMutation,
    UpdateSubscriptionBillingMutationVariables
  >;
